// import HomeWidgetFolder from 'modules/folder/components/HomeWidgetFolder'
import type { FC } from 'react'
import React from 'react'
import { IoLaptopOutline, IoPersonOutline } from 'react-icons/io5'

import Breadcrumbs from '@/components/Breadcrumb'

import StatisticCard from './components/StatisticCard'

const HomePage: FC = () => {
  const breadcrumbItems = [{ title: 'Home', url: '/' }]
  return (
    <div className='h-dvh flex flex-col gap-5 '>
      <Breadcrumbs items={breadcrumbItems} />
      <div className='w-full'>
        <div className='grid grid-cols-1 gap-2 lg:grid-cols-4 lg:gap-4'>
          <StatisticCard title='Users' value={30} icon={<IoPersonOutline />} />
          <StatisticCard title='Projects' value={5} icon={<IoLaptopOutline />} />
        </div>
      </div>
    </div>
  )
}

export default HomePage
