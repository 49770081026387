/* eslint-disable no-underscore-dangle */
import axios from 'axios'

import { reset } from '@/modules/auth/reducers/authReducer'

let store: any

export const injectStore = (_store: any) => {
  store = _store
}

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

api.interceptors.request.use(
  (config) => {
    const { token } = store.getState().auth

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => {
    return response.data
  },
  async (err) => {
    const { config } = err

    if (config.url !== '/auth/login' && err.response?.status === 401) {
      try {
        store.dispatch(reset())

        return await Promise.reject(err)
      } catch (error: any) {
        return Promise.reject(error)
      }
    }

    return Promise.reject(err)
  }
)

export default api
