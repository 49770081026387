import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '@/common/api'
import type { RootState } from '@/store'

interface ProjectState {
  items: any[]
  total: number
  totalPages: number
  filter: {
    page: number
    limit: number
    search?: string
    sorts?: string
    fields?: string
  }
}

const initialState: ProjectState = {
  items: [],
  total: 0,
  totalPages: 0,
  filter: {
    page: 1,
    limit: 10,
    search: undefined,
    sorts: '-createdAt',
    fields: undefined
  }
}

export const fetchProjects = createAsyncThunk('projects/fetchProjects', async (_, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState
    const params = { ...state.projects.filter }

    if (params.search === '') {
      delete params.search
    }
    if (params.fields === '') {
      delete params.fields
    }
    if (params.sorts === '') {
      delete params.sorts
    }
    const rs = await api.get('projects', { params })
    return rs
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

type ProjectCreateDto = {
  name: string
}

export const createProject = createAsyncThunk('projects/createProject', async (payload: ProjectCreateDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.post('projects', payload)
    return rs
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/**
 * Get projects by id
 */

type ProjectEditDto = {
  projectId: string
}

export const getProjectById = createAsyncThunk('projects/getProject', async ({ projectId }: ProjectEditDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.get(`projects/${projectId}`)
    return { id: rs.id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const updateProject = createAsyncThunk('projects/updateProject', async (payload: any, { rejectWithValue }) => {
  try {
    const { id, ...updateInfo } = payload
    const rs: any = await api.put(`projects/${payload.id}`, updateInfo)
    return rs
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

type ProjectDeleteDto = {
  projectId: string
}

export const deleteProject = createAsyncThunk('projects/deleteProject', async (payload: ProjectDeleteDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.delete(`projects/${payload.projectId}`)
    return { id: payload.projectId, status: rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const projectsSlice = createSlice({
  name: 'cloud_admin_projects',
  initialState,
  reducers: {
    reset: (state) => {
      state.total = initialState.total
      state.totalPages = initialState.totalPages
      state.items = initialState.items
      state.filter = JSON.parse(JSON.stringify(initialState.filter))
    },
    updateFilter: (state, action) => {
      state.filter = { ...state.filter, ...action.payload }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.fulfilled, (state, action) => {
        const { total, items } = action.payload as any
        state.items = items
        state.total = total
      })
      .addCase(createProject.fulfilled, (state, action) => {
        const info = action.payload
        const index = state.items.findIndex((item) => item.id === info.id)
        if (index >= 0) {
          const newObject = Object.assign(state.items[index], info)
          state.items.splice(index, 1, newObject)
        } else {
          state.items.unshift(info)
          state.total += 1
        }
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        const info = action.payload
        const index = state.items.findIndex((item) => item.id === info.id)
        if (index >= 0) {
          const newObject = Object.assign(state.items[index], info)
          state.items.splice(index, 1, newObject)
        }
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        const index = state.items.findIndex((item) => item.id === action.payload.id)
        if (index >= 0) {
          state.items.splice(index, 1)
          state.total -= 1
        }
      })
  }
})

export const { reset, updateFilter } = projectsSlice.actions
export default projectsSlice.reducer
