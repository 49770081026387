import { Button, Form, Input, message } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { changePassword } from '@/common/reducers/meReducer'

const UpdatePassword = () => {
  const dispatch = useDispatch<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [messageApi, contextHolder] = message.useMessage()

  const [form] = Form.useForm()
  const onFinish = async (formData: any) => {
    if (formData.newPassword !== formData.confirmPassword) {
      messageApi.error('New password and confirm password not match')
      return
    }
    try {
      setLoading(true)
      await dispatch(
        changePassword({
          password: formData.password,
          newPassword: formData.newPassword
        })
      ).unwrap()
      messageApi.success('Update password successfully')
      form.resetFields()
    } catch (error: any) {
      if (error?.message) {
        messageApi.error(error.message)
      } else {
        messageApi.error('Something went wrong')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='flex flex-col gap-5'>
      {contextHolder}
      <div className='text-lg font-bold'>Change Password</div>
      <Form form={form} layout='vertical' onFinish={onFinish} autoComplete='off'>
        <div className='flex flex-1 flex-col'>
          <Form.Item label='Old Password' name='password' rules={[{ required: true, message: 'Please enter old password' }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item label='New Password' name='newPassword' rules={[{ required: true, message: 'Please enter old password' }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item label='Confirm Password' name='confirmPassword' rules={[{ required: true, message: 'Please enter old password' }]}>
            <Input.Password />
          </Form.Item>
        </div>
        <div className='flex flex-row gap-5'>
          <Button type='default' htmlType='reset'>
            Cancel
          </Button>
          <Button type='primary' loading={loading} htmlType='submit'>
            Change
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default UpdatePassword
