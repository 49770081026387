import { Tag } from 'antd'
import React from 'react'

import { EUserStatus } from '@/common/enum'

type Props = {
  status: string | number
}

const UserStatus = (props: Props) => {
  let color: string = ''
  let tag: string = ''
  switch (props.status) {
    case EUserStatus.INACTIVE:
      color = 'grey'
      tag = 'Inactive'
      break
    case EUserStatus.ACTIVE:
      color = 'green'
      tag = 'Active'
      break
    case EUserStatus.SPAM:
      color = 'orange'
      tag = 'Spam'
      break
    case EUserStatus.BANNED:
      color = 'red'
      tag = 'Banned'
      break
    case EUserStatus.TRASH:
      color = 'black'
      tag = 'Trash'
      break
    default:
      color = 'default'
      tag = 'Unknown'
      break
  }

  return (
    <Tag color={color} key={tag}>
      {tag.toUpperCase()}
    </Tag>
  )
}

export default UserStatus
