import { UploadOutlined } from '@ant-design/icons'
import { Button, Empty, notification, Pagination, Select, Space, Spin, Upload } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import type { RootState } from '@/store'

import ItemsCardUpload from './components/ItemsCardUpload'
import UploadFile from './components/UploadFile'
import { getAssetsByProjectId, updateFilter, uploadFile } from './reducers/assetsReducers'

const ProjectAssets = () => {
  const params = useParams()
  const dispatch = useDispatch<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [dragging, setDragging] = useState(false)
  const [api, contextHolder] = notification.useNotification()

  const listAssetsProject = useSelector((state: RootState) => state.assetsProjects)

  const [sort, setSort] = useState(listAssetsProject.filter.sorts)

  const handleGetAssetByProjectId = async () => {
    setLoading(true)
    try {
      if (params.id) {
        await dispatch(getAssetsByProjectId({ projectId: params.id })).unwrap()
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const onChangePage = async (page: number, pageSize?: number) => {
    const newFilter = {
      ...listAssetsProject.filter,
      page,
      ...(pageSize && { limit: pageSize })
    }
    await dispatch(updateFilter(newFilter))
    await handleGetAssetByProjectId()
  }

  const handleUploadFile = async (file: any) => {
    const formData = new FormData()
    formData.append('file', file)
    try {
      const rs = await dispatch(uploadFile({ projectId: params.id ?? '', formData })).unwrap()
      if (rs) {
        api.success({
          message: 'Uploaded file',
          description: `File ${file.name} has been uploaded.`,
          placement: 'bottomRight',
          duration: 5
        })
      } else {
        api.error({
          message: 'Upload failed',
          description: `File ${file.name} cannot be uploaded.`,
          placement: 'bottomRight'
        })
      }
    } catch (error) {}
  }

  const handleChangeSort = async (value: string) => {
    setSort(value)
    let newFilter = {}
    if (value !== sort) {
      newFilter = { ...listAssetsProject.filter, sorts: value }
      await dispatch(updateFilter(newFilter))
      await handleGetAssetByProjectId()
    }
  }

  useEffect(() => {
    if (params.id) {
      handleGetAssetByProjectId()
    }
  }, [params.id])

  const handleDragOver = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault()
      e.stopPropagation()
      if (!dragging) {
        setDragging(true)
      }
    },
    [dragging]
  )

  const handleDragLeave = (e: React.DragEvent) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      setDragging(false)
    }
  }

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)
    const { files } = e.dataTransfer
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        handleUploadFile(files[i])
      }
    }
  }

  const handleDropCustom = (info: any) => {
    if (info?.file) {
      handleUploadFile(info.file)
    }
  }

  return (
    <div>
      {contextHolder}
      <div className='relative flex w-full flex-col gap-5' onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
        {dragging && (
          <div className='absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-white opacity-90'>
            <div onDragOver={handleDragOver} onDragLeave={handleDragLeave} className='h-full w-full bg-white'>
              <UploadFile />
            </div>
          </div>
        )}
        <div className='flex flex-row items-center justify-between'>
          <Upload multiple customRequest={handleDropCustom} showUploadList={false}>
            <Button type='primary' aria-multiline icon={<UploadOutlined />} className='text-base' aria-label='Upload File' title='Upload File'>
              Upload File
            </Button>
          </Upload>
          <div className='flex flex-row justify-end'>
            <Space wrap>
              <div>
                <span>Sort by: </span>
                <Select
                  defaultValue='createdAt'
                  style={{ width: 120 }}
                  onChange={handleChangeSort}
                  value={sort}
                  options={[
                    { value: '-createdAt', label: 'Latest' },
                    { value: 'createdAt', label: 'Oldest' }
                  ]}
                />
              </div>
            </Space>
          </div>
        </div>

        {!loading ? (
          <div className='z-0'>
            {listAssetsProject && listAssetsProject.items.length > 0 ? (
              <div>
                <div className='grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-5'>
                  {listAssetsProject.items.map((file: any) => (
                    <ItemsCardUpload
                      key={file.id}
                      id={file.id}
                      name={file.originalname}
                      createAt={file.createdAt}
                      type={file.type}
                      url={file.url}
                      meta={file.meta}
                    />
                  ))}
                </div>
                <div className='flex w-full justify-center p-5'>
                  <Pagination
                    onChange={onChangePage}
                    showSizeChanger
                    defaultCurrent={listAssetsProject.filter.page}
                    total={listAssetsProject.total}
                    pageSize={listAssetsProject.filter.limit}
                  />
                </div>
              </div>
            ) : (
              <div className='flex w-full justify-center'>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </div>
        ) : (
          <Spin />
        )}
      </div>
    </div>
  )
}

export default ProjectAssets
