import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button, Menu, Tooltip } from 'antd'
import React, { useState } from 'react'
import { IoHomeOutline, IoLaptopOutline, IoPersonOutline } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import type { RootState } from '@/store'

interface MenuItem {
  name: string
  key: string
  label: string | React.ReactNode
  icon?: React.ReactNode
}

const Sidebar = () => {
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn)
  const [collapsed, setCollapsed] = useState(false)
  const location = useLocation()

  if (!isLoggedIn) {
    return ''
  }

  const menuItem: MenuItem[] = [
    {
      name: 'Dashboard',
      key: '/',
      label: <Link to='/'>Dashboard</Link>,
      icon: <IoHomeOutline />
    },
    {
      name: 'Projects',
      key: '/projects',
      label: <Link to='/projects'>Projects</Link>,
      icon: <IoLaptopOutline />
    },
    {
      name: 'Users',
      key: '/users',
      label: <Link to='/users'>Users</Link>,
      icon: <IoPersonOutline />
    }
  ]

  // Tìm key phù hợp nhất để active
  const selectedKey = `/${location.pathname.split('/')[1]}`

  const items = menuItem.map((item) => ({
    key: item.key,
    icon: item.icon,
    label: collapsed ? (
      <Tooltip className='text-white' title={item.name} placement='right'>
        <Link className='text-white' to={item.key} />
      </Tooltip>
    ) : (
      item.label
    )
  }))

  return (
    <div className={collapsed ? 'relative min-w-[70px] max-w-[70px]' : ' min-w-[200px] max-w-[200px]'}>
      <div
        className={`fixed flex h-[-webkit-fill-available] min-w-[200px] max-w-[200px] flex-auto shrink-0  flex-col justify-between overflow-y-auto border-r-[1px] bg-white ${
          collapsed ? 'min-w-[70px] max-w-[70px]' : ' min-w-[200px] max-w-[200px] '
        }`}
      >
        <Menu className='mt-2 border-r-0' mode='inline' defaultOpenKeys={['sub1']} selectedKeys={[selectedKey]} items={items} />
        <div className=' flex w-full flex-row justify-center p-8'>
          <Button
            className=' bg-primary px-3 py-5 text-xs text-white'
            type='text'
            onClick={() => {
              setCollapsed(!collapsed)
            }}
          >
            {collapsed ? <RightOutlined /> : <LeftOutlined />}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
