import React from 'react'

import ProfileIndex from './ProfileIndex'

export const ProfileRoutes = [
  {
    path: 'profile',
    element: <ProfileIndex />,
    children: [{ path: 'change-password' }]
  }
]
