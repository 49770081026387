import { CheckOutlined, CopyOutlined } from '@ant-design/icons'
import { Button, Form, Input, message, Tooltip } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { getUpdatedDiff } from '@/common/utils'

import { getProjectById, updateProject } from '../reducers/projectsReducers'
import ConfirmDelete from './ConfirmDelete'

const UpdateProjects = () => {
  const dispatch = useDispatch<any>()
  const params = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage()
  const [infoProjects, setInfoProjects] = useState<any>({})
  const [copyText, setCopyText] = useState<boolean>(false)
  const [disable, setDisable] = useState<any>({})

  const onFinish = async (formData: any) => {
    const updateInfo = getUpdatedDiff(infoProjects, formData)
    if (isEmpty(updateInfo)) {
      return
    }
    try {
      setLoading(true)
      const rs = await dispatch(updateProject({ id: infoProjects.id, ...updateInfo })).unwrap()
      if (!isEmpty(rs)) {
        setDisable(true)
        messageApi.success('Update project successfully')
      } else {
        messageApi.error('Update project failed')
      }
    } catch (error: any) {
      if (error?.message) {
        messageApi.error(error.message)
      } else {
        messageApi.error('Something went wrong')
      }
    } finally {
      setLoading(false)
    }
  }

  const getInforProjects = async () => {
    try {
      if (params.id) {
        const rs = await dispatch(getProjectById({ projectId: params.id })).unwrap()
        form.setFieldsValue(rs)
        setInfoProjects(rs)
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (params.id) {
      getInforProjects()
    }
  }, [params.id])

  const handleChangeValue = (changeVal: any, changeAllVal: any) => {
    const updateInfo = getUpdatedDiff(infoProjects, changeAllVal)
    if (!isEmpty(updateInfo)) {
      setDisable(false)
    } else {
      setDisable(true)
    }
  }

  const handleCopyApiKey = () => {
    setCopyText(true)
    try {
      const apiKey = form.getFieldValue('apiKey')
      if (apiKey) {
        navigator.clipboard.writeText(apiKey)
        messageApi.success('API Key copied to clipboard!')
      }
    } catch (error) {
    } finally {
      setTimeout(() => {
        setCopyText(false)
      }, 3000)
    }
  }

  const truncateApiKey = (apiKey: string) => {
    if (apiKey && apiKey.length > 45) {
      return `${apiKey.slice(0, 45)}...`
    }
    return apiKey
  }

  return (
    <div className='flex-auto rounded border p-5'>
      {contextHolder}
      <div className='flex flex-col gap-5'>
        <div className='text-lg font-bold'>Update Project</div>
        <Form form={form} layout='vertical' onFinish={onFinish} onValuesChange={handleChangeValue} initialValues={infoProjects}>
          <div className='flex flex-1 flex-col'>
            <Form.Item label='Name' name='name' rules={[{ required: true, message: 'Please enter name' }]} className='text-sm'>
              <Input />
            </Form.Item>

            <Form.Item label='Api Key' className=' text-sm'>
              <div className='flex flex-row gap-1'>
                <Input
                  className={`${copyText ? 'pointer-events-none' : ''} cursor-pointer select-none bg-gray-100`}
                  value={truncateApiKey(form.getFieldValue('apiKey'))}
                  readOnly
                  onClick={handleCopyApiKey}
                />
                <Tooltip title='Copy API Key'>
                  <Button
                    icon={!copyText ? <CopyOutlined /> : <CheckOutlined />}
                    onClick={handleCopyApiKey}
                    className={copyText ? 'pointer-events-none' : ''}
                  />
                </Tooltip>
              </div>
            </Form.Item>
          </div>
          <div className='flex items-center justify-between'>
            <Button disabled={disable} loading={loading} type='primary' htmlType='submit'>
              Update
            </Button>
            <ConfirmDelete projectId={infoProjects.id} />
          </div>
        </Form>
      </div>
    </div>
  )
}

export default UpdateProjects
