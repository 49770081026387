import { Button, Empty, Pagination, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { BsGrid, BsList } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'

import Breadcrumbs from '@/components/Breadcrumb'
import type { RootState } from '@/store'

import ItemsCard from './components/ItemsCard'
import ModalCreateProject from './components/ModalCreateProject'
import SearchProjects from './components/SearchProjects'
import SortProject from './components/SortProject'
import { fetchProjects, updateFilter } from './reducers/projectsReducers'

const ProjectIndex = () => {
  const dispatch = useDispatch<any>()
  const listProject = useSelector((state: RootState) => state.projects)
  const filters = useSelector((state: RootState) => state.projects.filter)

  const [loading, setLoading] = useState(false)

  const getAllProjects = async () => {
    try {
      setLoading(true)
      await dispatch(fetchProjects()).unwrap()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const onChangePage = async (page: number, pageSize?: number) => {
    const newFilter = {
      ...listProject.filter,
      page,
      ...(pageSize && { limit: pageSize })
    }
    await dispatch(updateFilter(newFilter))
  }

  useEffect(() => {
    getAllProjects()
  }, [filters])

  const breadcrumbItems = [
    { title: 'Home', url: '/' },
    { title: 'Projects', url: '/Projects' }
  ]

  const hasItems = listProject.items.length > 0

  return (
    <div className='h-dvh flex flex-col gap-5'>
      <Breadcrumbs items={breadcrumbItems} />
      <div className='flex w-full flex-col gap-5 rounded bg-white p-5'>
        <div className='flex flex-row justify-between'>
          <div className='flex flex-row gap-1'>
            <Button icon={<BsGrid />} />
            <Button icon={<BsList />} />
          </div>
          <ModalCreateProject />
        </div>
        <div className='flex flex-row items-center justify-between'>
          <div className='flex flex-row justify-end'>
            <SearchProjects />
          </div>
          <div className='flex flex-row justify-end'>
            <SortProject />
          </div>
        </div>
        {loading ? (
          <Spin size='large' />
        ) : (
          <div className='flex w-full flex-col gap-5'>
            {hasItems ? (
              <>
                <div className='grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4'>
                  {listProject.items.map((item: any) => (
                    <ItemsCard key={item.id} id={item.id} name={item.name} createAt={item.createdAt} />
                  ))}
                </div>
                <div className='flex w-full justify-center p-5'>
                  <Pagination
                    onChange={onChangePage}
                    showSizeChanger
                    defaultCurrent={listProject.filter.page}
                    total={listProject.total}
                    pageSize={listProject.filter.limit}
                  />
                </div>
              </>
            ) : (
              <div className='flex w-full justify-center'>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ProjectIndex
