import { FileImageOutlined, FileOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Dropdown, Image, notification, Space, Tooltip } from 'antd'
import React, { useEffect } from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { useDispatch } from 'react-redux'

import { formatTime } from '@/common/utils'

import { deleteFile } from '../reducers/assetsReducers'

type Props = {
  id: string
  name: string
  type: string
  url: string
  meta?: any
  createAt: string
}

const ItemsCardUpload = ({ id, name, type, url, meta, createAt }: Props) => {
  const dispatch = useDispatch<any>()

  const handleDeleteFile = async () => {
    try {
      await dispatch(deleteFile({ id }))
      notification.success({
        message: 'Delete file successfully',
        description: `File  ${name} has been deleted`,
        placement: 'bottomRight'
      })
    } catch (error) {
      notification.warning({
        message: 'Delete file failed',
        description: `File ${name} cannot be deleted`,
        placement: 'bottomRight'
      })
    }
  }

  const items: MenuProps['items'] = [
    {
      label: <span>Edit</span>,
      key: '0'
    },
    {
      onClick: handleDeleteFile,
      label: <span>Delete</span>,
      key: '1'
    }
  ]

  const converName =
    name.length > 17 ? (
      <Tooltip placement='topRight' title={name}>
        {name.slice(0, 17)}...
      </Tooltip>
    ) : (
      name
    )

  const hidePreviewMask = () => {
    const maskElements = document.querySelectorAll('.ant-image-mask')
    maskElements.forEach((element) => {
      const maskElement = element as HTMLElement
      maskElement.classList.add('hidden')
    })
  }

  useEffect(() => {
    hidePreviewMask()
  }, [])

  return (
    <div data-id={id} data-format={meta?.format} className='w-full cursor-pointer overflow-hidden rounded-lg bg-gray-100 shadow hover:shadow-lg'>
      <div className='flex w-full flex-row items-center justify-between p-2'>
        <div className='flex flex-row gap-2  text-base font-bold'>
          <span className='w-5'>{type === 'image' ? <FileImageOutlined /> : <FileOutlined />}</span>
          <span>{converName}</span>
        </div>

        <div>
          <Dropdown menu={{ items }} trigger={['click']}>
            <div onClick={(e) => e.preventDefault()}>
              <Space>
                <BsThreeDotsVertical />
              </Space>
            </div>
          </Dropdown>
        </div>
      </div>
      <div className='m-0 w-full p-1'>
        <div className='h-[150px] w-full overflow-hidden'>{type === 'image' ? <Image className='h-full w-full rounded object-cover' src={url} /> : null}</div>
      </div>
      <span className='px-2 py-3 text-sm'>Create At: {formatTime(new Date(createAt))}</span>
    </div>
  )
}

export default ItemsCardUpload
