/* eslint-disable import/no-cycle */
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import meReducer from '@/common/reducers/meReducer'
import authReducer from '@/modules/auth/reducers/authReducer'
import assetsReducers from '@/modules/projects/reducers/assetsReducers'
import projectsReducers from '@/modules/projects/reducers/projectsReducers'
import userReducer from '@/modules/users/reducers/userReducer'

const persistConfig = {
  key: 'logger',
  storage,
  whitelist: ['auth', 'me'],
  transforms: []
}

const rootReducer = combineReducers({
  auth: authReducer,
  me: meReducer,
  user: userReducer,
  projects: projectsReducers,
  assetsProjects: assetsReducers
  // App
})

const middlewares: any = [thunk]

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
  devTools: process.env.NODE_ENV !== 'production'
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
