import { Button, Result } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

const Error404: React.FC = () => {
  return (
    <div className='h-full flex-1 flex-col center'>
      <Result
        status='404'
        title='404'
        subTitle='Sorry, the page you visited does not exist.'
        extra={
          <Link to='/' className='mt-6'>
            <Button type='primary'>Back to home</Button>
          </Link>
        }
      />
    </div>
  )
}

export default Error404
