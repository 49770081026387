import React from 'react'

import ProjectAssets from './ProjectAssets'
import ProjectDetail from './ProjectDetail'
import ProjectIndex from './ProjectsIndex'

export const ProjectsRoutes = [
  {
    path: 'projects',
    element: <ProjectIndex />
  },
  {
    path: 'projects/:id',
    element: <ProjectDetail />,
    children: [{ path: 'upload', element: <ProjectAssets /> }]
  }
]
