import { Button, Form, Input, message, Modal } from 'antd'
import React, { useState } from 'react'
import { BsPlus } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { createUser } from '../reducers/userReducer'

const ModalCreate = () => {
  const dispatch = useDispatch<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()
  const navigate: NavigateFunction = useNavigate()
  const [form] = Form.useForm()
  const showModal = () => {
    setIsModalOpen(true)
  }

  const onFinish = async () => {
    try {
      setLoading(true)
      const values = await form.getFieldsValue()
      await dispatch(createUser(values)).unwrap()

      setLoading(false)
      setIsModalOpen(false)
      messageApi.success('Create user success')
      form.resetFields()
      navigate('/users')
    } catch (error: any) {
      setLoading(false)
      if (error?.message) {
        messageApi.error(error.message)
      } else {
        messageApi.error('Something went wrong')
      }
    }
  }

  return (
    <>
      {contextHolder}
      <Button className='p-2' type='primary' onClick={showModal}>
        <BsPlus />
        Add user
      </Button>

      <Modal title='Add new user' open={isModalOpen} footer={false} onCancel={() => setIsModalOpen(false)} maskClosable={false}>
        <Form form={form} layout='vertical' onFinish={onFinish}>
          <div className='flex flex-1 flex-col'>
            <Form.Item label='Name' name='name' rules={[{ required: true, message: 'Please enter name' }]} className='text-sm'>
              <Input />
            </Form.Item>
            <Form.Item label='Username' name='username' rules={[{ required: true, message: 'Please enter username' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label='Email'
              name='email'
              rules={[
                { required: true, message: 'Please enter email' },
                { type: 'email', message: 'Invalid email format!' }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label='Password' name='password' rules={[{ required: true, message: 'Please enter password' }]}>
              <Input.Password />
            </Form.Item>
          </div>
          <div>
            <Button loading={loading} type='primary' htmlType='submit'>
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default ModalCreate
