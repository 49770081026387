import type { FC } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { fetchMe } from '@/common/reducers/meReducer'
import { logout } from '@/modules/auth/reducers/authReducer'
import type { RootState } from '@/store'

import Header from './Header'
import Sidebar from './Sidebar'

const Layout: FC = () => {
  const location = useLocation()
  const navigate: NavigateFunction = useNavigate()

  const dispatch = useDispatch<any>()
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn)

  const [showHeaderAndSidebar, setShowHeaderAndSidebar] = useState(isLoggedIn)

  const isLoggedInState = useRef<boolean>(isLoggedIn)
  const guestRoutes = ['/oauth/google', '/login', '/register', '/confirm-email', '/reset-password']
  const checkShowHeaderAndSidebar = () => {
    if (isLoggedIn && !location.pathname.startsWith('/editor')) {
      setShowHeaderAndSidebar(true)
    } else {
      setShowHeaderAndSidebar(false)
    }
  }

  const handleFetchMe = async () => {
    try {
      await dispatch(fetchMe())
    } catch (error: any) {
      if (error === 'Forbiden') {
        await dispatch(logout())
        navigate('/login')
      }
    }
  }

  useEffect(() => {
    if (isLoggedIn) {
      handleFetchMe()
    }

    if (!guestRoutes.includes(location.pathname)) {
      if (!isLoggedIn) {
        navigate('/login')
      }
    }
  }, [])

  useEffect(() => {
    if (isLoggedInState.current === true && isLoggedIn === false) {
      navigate('/login')
    }

    checkShowHeaderAndSidebar()
  }, [isLoggedIn])

  useEffect(() => {
    if (!guestRoutes.includes(location.pathname)) {
      if (!isLoggedIn) {
        navigate('/login')
      }
    }

    checkShowHeaderAndSidebar()
  }, [location])

  return (
    <div className='flex min-h-screen flex-row'>
      <div className='flex flex-1 flex-col'>
        <div className='flex flex-auto flex-col'>
          {showHeaderAndSidebar && <Header />}
          <div className='flex flex-auto flex-row'>
            {showHeaderAndSidebar && <Sidebar />}
            <div className='box-border flex min-h-0 w-0 flex-auto flex-col bg-[#f5f5f5]'>
              <div className='m-0 min-h-[280px] flex-auto rounded p-5'>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout
