import { Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

import Breadcrumbs from '@/components/Breadcrumb'

import UpdateProjects from './components/UpdateProjects'
import { getProjectById } from './reducers/projectsReducers'

const ProjectDetail: React.FC = () => {
  const navigate: NavigateFunction = useNavigate()
  const params = useParams()
  const dispatch = useDispatch<any>()
  const [infoProjects, setInfoProjects] = useState<any>({})
  const [activeTab, setActiveTab] = React.useState('')
  const location = useLocation()
  const { pathname } = location
  const path: string[] = pathname.split('/')
  const threePathname = path[3]

  const arrDetails = [
    {
      id: 1,
      name: 'Information',
      key: '',
      template: <UpdateProjects />
    },
    {
      id: 2,
      name: 'Upload',
      key: 'upload',
      template: <Outlet />
    }
  ]
  const handleChangeTabs = (key: string) => {
    setActiveTab(key)
    navigate(key)
  }

  const getInforProjects = async () => {
    try {
      if (params.id) {
        const rs = await dispatch(getProjectById({ projectId: params.id })).unwrap()
        setInfoProjects(rs)
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (params.id) {
      getInforProjects()
    }
    if (threePathname) {
      setActiveTab(threePathname)
    } else {
      setActiveTab('')
    }
  }, [params.id])

  const breadcrumbItems = [
    { title: 'Home', url: '/' },
    { title: 'Projects', url: '/Projects' },
    { title: infoProjects.name, url: `/projects/${infoProjects.id}` }
  ]

  return (
    <div className='h-dvh flex flex-col gap-5'>
      <Breadcrumbs items={breadcrumbItems} />
      <div className='flex w-full flex-auto flex-col gap-5 rounded bg-white p-5'>
        <Tabs
          tabPosition='left'
          activeKey={activeTab}
          onChange={handleChangeTabs}
          items={arrDetails.map((item) => ({
            label: item.name,
            key: String(item.key),
            children: item.template
          }))}
        />
      </div>
    </div>
  )
}

export default ProjectDetail
