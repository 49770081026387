import { Breadcrumb } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

type BreadcrumbItem = {
  title: string
  url: string
}

type Props = {
  items: BreadcrumbItem[]
}

const Breadcrumbs: React.FC<Props> = ({ items }) => {
  const breadcrumbItems = items.map((item) => ({
    title: <Link to={item.url}>{item.title}</Link>
  }))

  return <Breadcrumb className='rounded bg-white p-3 shadow' items={breadcrumbItems} />
}

export default Breadcrumbs
