import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '@/common/api'
import type { RootState } from '@/store'

export interface UserState {
  items: any[]
  total: number
  filter: {
    page: number
    limit: number
    search?: string
    sorts?: string
    fields?: string
    status?: string
  }
}

const initialState: UserState = {
  items: [],
  total: 0,
  filter: {
    page: 1,
    limit: 10,
    search: '',
    sorts: '-createdAt',
    fields: undefined,
    status: undefined
  }
}

export const fetchUsers = createAsyncThunk('/users/list', async (_, { getState, rejectWithValue }) => {
  const state = getState() as RootState
  try {
    const params = { ...state.user.filter }
    if (params.search === '') {
      delete params.search
    }
    if (params.fields === '') {
      delete params.fields
    }
    if (params.sorts === '') {
      delete params.sorts
    }
    if (params.status === '') {
      delete params.sorts
    }
    return await api.get('users', { params })
  } catch (error: any) {
    return rejectWithValue(error?.reponse?.data)
  }
})

type UserCreateDto = {
  name: string
  username: string
  email: string
  password: string
}

export const createUser = createAsyncThunk('/users/create', async (payload: UserCreateDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.post(`users`, payload)
    return rs
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

// get users by id
type UserGetDto = {
  userId: string
}
export const getUserDetail = createAsyncThunk('/users/get', async ({ userId }: UserGetDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.get(`users/${userId}`)
    return { id: rs.id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

// update user
export const updateUser = createAsyncThunk('/users/update', async (payload: any, { rejectWithValue }) => {
  try {
    if (payload?.permissions && payload?.permissions.includes('')) {
      payload.permissions = []
    }
    if (payload?.status) {
      payload.status = parseInt(payload.status, 10)
    }
    const { id, ...updateInfo } = payload
    const rs: any = await api.put(`users/${payload.id}`, updateInfo)
    return { id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

type UserDeleteDto = {
  id: string
}

export const deleteUser = createAsyncThunk('/users/delete', async (payload: UserDeleteDto, { rejectWithValue }) => {
  try {
    const rs = await api.delete(`users/${payload.id}`)
    return { id: payload.id, status: rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const userSlice = createSlice({
  name: 'cloud_admin_user',
  initialState,
  reducers: {
    reset: (state) => {
      state.total = initialState.total
      state.items = initialState.items
      state.filter = JSON.parse(JSON.stringify(initialState.filter))
    },
    updateFilter(state, { payload }) {
      state.filter = { ...state.filter, ...payload }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.fulfilled, (state, action) => {
        const { total, items, page } = action.payload as any
        state.items = items
        state.total = total
        state.filter.page = page
      })
      .addCase(createUser.fulfilled, (state, action) => {
        const info = action.payload
        const index = state.items.findIndex((item) => item.id === info.id)
        if (index >= 0) {
          const newObject = Object.assign(state.items[index], info)
          state.items.splice(index, 1, newObject)
        } else {
          state.items.unshift(info)
          state.total += 1
        }
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const info = action.payload
        const index = state.items.findIndex((item) => item.id === info.id)
        if (index >= 0) {
          const newObject = Object.assign(state.items[index], info)
          state.items.splice(index, 1, newObject)
        }
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        const { id } = action.payload
        const index = state.items.findIndex((item) => item.id === id)
        if (index >= 0) {
          state.items.splice(index, 1)
        }
        state.total -= 1
      })
  }
})
export const { reset, updateFilter } = userSlice.actions
export default userSlice.reducer
