import { Button, Form, Input, message } from 'antd' // Import the Form component from 'antd'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Import the useParams from 'react-router-dom'
import { updateProfile } from '@/common/reducers/meReducer'
import { getUpdatedDiff } from '@/common/utils'
import type { RootState } from '@/store'

const UpdateProfile = () => {
  const dispatch = useDispatch<any>()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(false)
  const [disable, setDisable] = useState<any>({})
  const [messageApi, contextHolder] = message.useMessage()

  const profile = useSelector((state: RootState) => state.auth.user)

  const onChangeValue = (changeVal: any, changeAllVal: any) => {
    const updateInfo = getUpdatedDiff(profile, changeAllVal)
    if (!isEmpty(updateInfo)) {
      setDisable(false)
    } else {
      setDisable(true)
    }
  }

  const onFinish = async (formData: any) => {
    const updateInfo = getUpdatedDiff(profile, formData)
    if (isEmpty(updateInfo)) {
      return
    }
    setLoading(true)
    try {
      const rs = await dispatch(updateProfile({ name: updateInfo.name })).unwrap()
      if (rs) {
        messageApi.success('Update profile successfully')
      } else {
        messageApi.error('Update profile failed')
      }
    } catch (error: any) {
      if (error?.message) {
        messageApi.error(error.message)
      } else {
        messageApi.error('Something went wrong')
      }
    } finally {
      setDisable(true)
      setLoading(false)
    }
  }
  return (
    <div className='flex flex-col gap-5'>
      {contextHolder}
      <div className='text-lg font-bold'>Update Profile</div>
      <Form form={form} layout='vertical' onFinish={onFinish} onValuesChange={onChangeValue} initialValues={profile}>
        <div className='flex flex-1 flex-col'>
          <Form.Item label='Username' name='username' className='text-sm'>
            <Input readOnly disabled className='bg-gray-100' />
          </Form.Item>
        </div>
        <Form.Item label='Email' name='email' className='text-sm'>
          <Input readOnly disabled className='bg-gray-100' />
        </Form.Item>
        <Form.Item label='Name' name='name' rules={[{ required: true, message: 'Please enter name' }]} className='text-sm'>
          <Input />
        </Form.Item>
        <div className='flex flex-row gap-5'>
          <Button type='default' htmlType='reset'>
            Cancel
          </Button>
          <Button disabled={disable} loading={loading} type='primary' htmlType='submit'>
            Update
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default UpdateProfile
