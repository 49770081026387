import React from 'react'

import UserDetail from './UserDetail'
import Users from './Users'

export const UsersRoutes = [
  {
    path: 'users',
    element: <Users />,
    children: [
      { path: '', element: <Users /> },
      { path: ':id', element: <UserDetail /> }
    ]
  }
]
