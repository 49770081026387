import React from 'react'

type Props = {
  title: string
  value: string | number
  icon: React.ReactNode
}

const StatisticCard = (props: Props) => (
  <div className='flex items-center rounded bg-white p-4 shadow-lg'>
    <div className='flex w-full flex-row gap-3'>
      <div className='flex flex-1 flex-col justify-start gap-3 lg:gap-5'>
        <span className='mb-0 text-lg font-bold lg:whitespace-nowrap'>{props.title}</span>
        <div className='flex items-end justify-start gap-5  text-left'>
          <span className='text-left text-2xl lg:text-4xl'>{props.icon}</span>
        </div>
      </div>
      <div className=' flex w-full flex-1 flex-col items-end justify-end'>
        <span className='text-4xl lg:text-6xl'>{props.value}</span>
      </div>
    </div>
  </div>
)

export default StatisticCard
