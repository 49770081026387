import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '@/common/api'
import { logout, setIsAdmin, setUser, updateAuthUser } from '@/modules/auth/reducers/authReducer'

export interface AuthUserState {
  // menus: any[]
}

const initialState: AuthUserState = {
  // menus: []
}

/*
 *****************************************
 *
 *
 */

export const fetchMe = createAsyncThunk('me/fetchMe', async (_, { rejectWithValue, dispatch }) => {
  try {
    const data = (await api.get('me')) as any
    dispatch(setUser(data))
    if (data?.permissions?.includes(9999)) {
      dispatch(setIsAdmin())
    }
    return {}
  } catch (error: any) {
    if (error?.response?.status === 401) {
      dispatch(logout())
    }
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 * Settings
 *
 */

export const updateProfile = createAsyncThunk('me/updateProfile', async (payload: any, { rejectWithValue, dispatch }) => {
  try {
    const data = await api.put('profile/settings', payload)
    dispatch(updateAuthUser(data))
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const changePassword = createAsyncThunk('me/change-password', async (payload: any, { rejectWithValue }) => {
  try {
    await api.put('profile/change-password', payload)
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

export const meSlice = createSlice({
  name: 'me',

  initialState,

  reducers: {},

  extraReducers: (_builder) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // builder.addCase(fetchMenus.fulfilled, (state, action) => {
    //   const { items } = action.payload as any
    //   state.menus = items
    // })
  }
})

export default meSlice.reducer
