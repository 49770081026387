import { Button, message, Popconfirm } from 'antd'
import React, { useState } from 'react'
import { BsFillTrash3Fill } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { deleteProject } from '../reducers/projectsReducers'

type Props = {
  projectId: string
}

const ConfirmDelete = ({ projectId }: Props) => {
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()
  const navigate: NavigateFunction = useNavigate()
  const dispatch = useDispatch<any>()

  const showPopconfirm = () => {
    setOpen(true)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleDeleteProject = async () => {
    try {
      setConfirmLoading(true)
      const rs = await dispatch(deleteProject({ projectId }))
      if (rs?.payload?.status === true) {
        navigate(`/projects`)
        setOpen(false)
        setConfirmLoading(false)
      } else {
        messageApi.error('Delete project failed')
      }
    } catch (error: any) {
      messageApi.error(error?.message ?? 'Something went wrong')
    } finally {
      setConfirmLoading(false)
    }
  }

  return (
    <div>
      {contextHolder}
      <Popconfirm
        title='Confirm'
        description='Are you sure you want to delete this project?'
        open={open}
        onConfirm={handleDeleteProject}
        okButtonProps={{ loading: confirmLoading }}
        onCancel={handleCancel}
        okType='danger'
      >
        <Button className='w-max' onClick={showPopconfirm} danger>
          <BsFillTrash3Fill />
        </Button>
      </Popconfirm>
    </div>
  )
}

export default ConfirmDelete
