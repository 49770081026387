import { InboxOutlined } from '@ant-design/icons'
import { notification, Upload } from 'antd'
import { random } from 'lodash'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { randomString } from '@/common/utils'

import { uploadFile } from '../reducers/assetsReducers'

const { Dragger } = Upload

const UploadFile = () => {
  const [api, contextHolder] = notification.useNotification()
  const dispatch = useDispatch<any>()
  const params = useParams()
  const [fileList, setFileList] = useState<any[]>([])

  const handleRemove = (file: any) => {
    const updatedList = fileList.filter((item) => item.uid !== file.uid)
    setFileList(updatedList)
  }

  const handleCustomRequest = async ({ file, onSuccess, onError }: any) => {
    const formData = new FormData()
    formData.append('file', file)
    const notificationKey = random(0, 1000000) + randomString(5)

    try {
      const response = await dispatch(uploadFile({ projectId: params.id ?? '', formData })).unwrap()

      if (response) {
        onSuccess('ok')
        api.success({
          key: notificationKey,
          message: 'Uploaded file',
          description: `File ${file.name} has been uploaded.`,
          placement: 'bottomRight',
          duration: 5
        })
      } else {
        onError('error')
        api.error({
          key: notificationKey,
          message: 'Upload failed',
          description: `File ${file.name} cannot be uploaded.`,
          placement: 'bottomRight'
        })
      }
    } catch (error) {
      onError(error)
      api.error({
        key: notificationKey,
        message: 'Upload failed',
        description: `File ${file.name} cannot be uploaded.`,
        placement: 'bottomRight'
      })
    }
  }

  return (
    <>
      {contextHolder}
      <Dragger className='w-full bg-white' multiple customRequest={handleCustomRequest} onRemove={handleRemove}>
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text text-black'>Drag files to this area to upload</p>{' '}
      </Dragger>
    </>
  )
}

export default UploadFile
