import { Input } from 'antd'
import { debounce } from 'lodash'
import React, { useCallback, useState } from 'react'
import { IoIosSearch } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'

import type { RootState } from '@/store'

import { updateFilter } from '../reducers/projectsReducers'

const SearchProjects = () => {
  const dispatch = useDispatch<any>()
  const searchFilter = useSelector((state: RootState) => state.projects.filter.search) as any
  const [value, setValue] = useState(searchFilter || '')

  const handleSearch = useCallback(
    debounce((text: string) => {
      dispatch(updateFilter({ search: text }))
    }, 500),
    []
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value
    setValue(text)
    handleSearch(text)
  }

  return (
    <Input placeholder='Please enter search' value={value} onChange={handleChange} className='w-full px-[11px] py-2 lg:w-[300px]' suffix={<IoIosSearch />} />
  )
}

export default SearchProjects
