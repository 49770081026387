import { Button, Form, Input, message, Modal } from 'antd'
import React, { useState } from 'react'
import { BsPlus } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { createProject } from '../reducers/projectsReducers'

const ModalCreateProject = () => {
  const dispatch = useDispatch<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()
  const navigate: NavigateFunction = useNavigate()
  const [form] = Form.useForm()

  const showModal = () => {
    setIsModalOpen(true)
  }

  const onFinish = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()
      await dispatch(createProject(values)).unwrap()
      setLoading(false)
      setIsModalOpen(false)
      messageApi.success('Create project successfully')
      form.resetFields()
      navigate('/projects')
    } catch (error: any) {
      setLoading(false)
      if (error?.message) {
        messageApi.error(error.message)
      } else {
        messageApi.error('Something went wrong')
      }
    }
  }

  return (
    <>
      {contextHolder}
      <Button className='p-2' type='primary' onClick={showModal}>
        <BsPlus />
        Add projects
      </Button>

      <Modal title='Add new project' open={isModalOpen} footer={false} onCancel={() => setIsModalOpen(false)} maskClosable={false}>
        <Form form={form} layout='vertical' onFinish={onFinish}>
          <div className='flex flex-1 flex-col'>
            <Form.Item label='Name Project' name='name' rules={[{ required: true, message: 'Please enter name' }]} className='text-sm'>
              <Input />
            </Form.Item>
          </div>
          <div>
            <Button loading={loading} type='primary' htmlType='submit'>
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default ModalCreateProject
