import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import DropDownProfile from '@/components/DropDownProfile'
import type { RootState } from '@/store'

const Header = () => {
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn)
  if (!isLoggedIn) {
    return ''
  }
  return (
    <div className='z-20 flex h-16'>
      <div className='fixed w-full bg-white px-8 py-5 shadow'>
        <div className='flex h-full flex-row items-center justify-between'>
          <Link to='/' className='w-[180px]'>
            <h3 className='m-0 mx-4 cursor-pointer text-[25px] font-bold text-primary'>Cloud</h3>
          </Link>
          <DropDownProfile />
        </div>
      </div>
    </div>
  )
}

export default Header
