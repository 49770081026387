import { DownOutlined, ProfileOutlined, UserOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Avatar, Dropdown, Space } from 'antd'
import React from 'react'
import { IoIosLogOut } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { logout } from '@/modules/auth/reducers/authReducer'
import type { RootState } from '@/store'

const DropDownProfile = () => {
  const navigate: NavigateFunction = useNavigate()
  const dispatch = useDispatch<any>()
  const profile = useSelector((state: RootState) => state.auth.user)
  const avatar = profile.name ? profile.name.slice(0, 1).toUpperCase() : <UserOutlined />

  const handleLogout = async () => {
    try {
      await dispatch(logout())
      navigate('/login')
    } catch (error: any) {}
  }

  const items: MenuProps['items'] = [
    {
      label: (
        <div className='pointer-events-none flex flex-col gap-0 border-b p-2'>
          <span className='font-bold'>{profile.name.length > 15 ? `${profile.name.slice(0, 15)}...` : profile.name}</span>
          <span>{profile.email}</span>
        </div>
      ),
      key: '#'
    },
    {
      label: 'Profile',
      key: 'profile',
      icon: <ProfileOutlined />,
      onClick: () => {
        navigate('/profile')
      }
    },
    {
      label: 'Logout',
      key: 'logout',
      icon: <IoIosLogOut />,
      onClick: () => {
        handleLogout()
      }
    }
  ]

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <div className='cursor-pointer text-white '>
        <Space>
          <Avatar size={28} className='bg-primary text-white' icon={avatar} />
          <DownOutlined className='text-xs text-black' />
        </Space>
      </div>
    </Dropdown>
  )
}

export default DropDownProfile
