import { Tabs } from 'antd'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'

import Breadcrumbs from '@/components/Breadcrumb'
import type { RootState } from '@/store'

import UpdatePassword from './components/UpdatePassword'
import UpdateProfile from './components/UpdateProfile'

const ProfileIndex = () => {
  const navigate: NavigateFunction = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const path: string[] = pathname.split('/')
  const secondPathname = path[2]
  const authUser = useSelector((state: RootState) => state.auth.user)
  const [activeTab, setActiveTab] = React.useState('')

  useEffect(() => {
    if (secondPathname) {
      setActiveTab(secondPathname)
    } else {
      setActiveTab('')
    }
  }, [secondPathname])

  const arrDetails = [
    {
      id: 1,
      name: 'Profile',
      key: '',
      template: <UpdateProfile />
    },
    {
      id: 2,
      name: 'Password',
      key: 'change-password',
      template: <UpdatePassword />
    }
  ]

  const breadcrumbItems = [
    { title: 'Home', url: '/' },
    { title: 'Profile', url: '/profile' }
  ]

  const handleChangeTabs = (key: string) => {
    setActiveTab(key)
    navigate(key)
  }

  if (!authUser) {
    return <div />
  }

  return (
    <div className='h-dvh flex flex-col gap-5'>
      <Breadcrumbs items={breadcrumbItems} />
      <div className='m-auto flex w-full flex-col justify-center gap-5 rounded bg-white p-5'>
        <div className='m-auto w-3/4'>
          <Tabs
            tabPosition='left'
            activeKey={activeTab}
            onChange={handleChangeTabs}
            items={arrDetails.map((item) => ({
              label: item.name,
              key: String(item.key),
              children: item.template
            }))}
          />
        </div>
      </div>
    </div>
  )
}

export default ProfileIndex
