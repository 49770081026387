import { Tooltip } from 'antd'
import React from 'react'
import { BsBoxSeamFill } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'

import { formatTime } from '@/common/utils'

type Props = {
  id: string
  name: string
  createAt: string
}

const ItemsCard = ({ id, name, createAt }: Props) => {
  const navigate = useNavigate()
  const converName =
    name.length > 30 ? (
      <Tooltip placement='topRight' title={name}>
        {name.slice(0, 30)}...
      </Tooltip>
    ) : (
      name
    )
  const handleDetailProject = () => {
    navigate(`/projects/${id}`)
  }

  return (
    <div className='w-full cursor-pointer overflow-hidden rounded bg-white shadow-md hover:shadow-lg' onClick={handleDetailProject}>
      <div className='w-full border-b-[1px] bg-gray-100 px-4 py-2 text-base font-bold capitalize'>{converName}</div>
      <div className='w-full p-4'>
        <div>
          <BsBoxSeamFill className='h-9 w-9 text-[#627aff]' />
        </div>
        <span className='text-sm'>Create At: {formatTime(new Date(createAt))}</span>
      </div>
    </div>
  )
}

export default ItemsCard
