import { Button, Form, Input, message, Select } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { EUserPermissions, EUserStatus } from '@/common/enum'
import { convertEnumToSelectOptions, getUpdatedDiff } from '@/common/utils'
import type { RootState } from '@/store'

import { getUserDetail, updateUser } from './reducers/userReducer'

const UserDetail = () => {
  const dispatch = useDispatch<any>()
  const params = useParams()
  const [info, setInfo] = useState<any>({})
  const [disable, setDisable] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [messageApi, contextHolder] = message.useMessage()
  const idLoggedIn = useSelector((state: RootState) => state.auth.user?.id)
  const [form] = Form.useForm()
  const getInfoUser = async () => {
    try {
      if (params.id) {
        const rs = await dispatch(getUserDetail({ userId: params.id })).unwrap()
        form.setFieldsValue(rs)
        setInfo(rs)
        setDisable(true)
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (params.id) {
      getInfoUser()
    }
  }, [params.id])

  const handleChangeValue = (changeVal: any, changeAllVal: any) => {
    const updateInfo = getUpdatedDiff(info, changeAllVal)
    if (!isEmpty(updateInfo)) {
      setDisable(false)
    } else {
      setDisable(true)
    }
  }

  const onFinish = async (formData: any) => {
    const updateInfo = getUpdatedDiff(info, formData)

    if (isEmpty(updateInfo)) {
      return
    }
    try {
      setLoading(true)
      await dispatch(updateUser({ id: info.id, ...updateInfo })).unwrap()
      messageApi.success('Update user successfully')
      setInfo(Object.assign(info, updateInfo))
      setDisable(true)
    } catch (error: any) {
      if (error?.message) {
        messageApi.error(error.message)
      } else {
        messageApi.error('Something went wrong')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='h-full'>
      {contextHolder}

      <div className='flex h-full flex-col justify-between'>
        <Form form={form} onValuesChange={handleChangeValue} layout='vertical' onFinish={onFinish} initialValues={info}>
          <div className='flex flex-1 flex-col'>
            <Form.Item className='mb-4 text-sm' label='Username' name='username' rules={[{ required: true, message: 'Please enter username' }]}>
              <Input readOnly disabled={idLoggedIn === info.id} className='bg-gray-100' />
            </Form.Item>
            <Form.Item
              className='mb-4  text-sm'
              label='Email'
              name='email'
              rules={[
                { required: true, message: 'Please enter email' },
                { type: 'email', message: 'Invalid email format!' }
              ]}
            >
              <Input type='email' disabled={idLoggedIn === info.id} className='bg-gray-100' readOnly />
            </Form.Item>
            <Form.Item className='mb-4 text-sm' label='Name' name='name' rules={[{ required: true, message: 'Please enter name' }]}>
              <Input />
            </Form.Item>
            <Form.Item className='mb-4 text-sm' label='Password' name='password'>
              <Input.Password />
            </Form.Item>
            <Form.Item className='mb-4 text-sm' label='Status' name='status'>
              <Select options={convertEnumToSelectOptions(EUserStatus)} />
            </Form.Item>

            <Form.Item className='mb-4 text-sm ' label='Permissions' name='permissions'>
              <Select mode='multiple' options={convertEnumToSelectOptions(EUserPermissions)} />
            </Form.Item>
          </div>
          <div className='flex flex-row justify-center'>
            <Button disabled={disable} loading={loading} type='primary' htmlType='submit'>
              Update
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default UserDetail
