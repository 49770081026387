import { Select, Space } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import type { RootState } from '@/store'

import { updateFilter } from '../reducers/projectsReducers'

const SortProject = () => {
  const dispatch = useDispatch<any>()
  const listProject = useSelector((state: RootState) => state.projects)
  const [sort, setSort] = useState(listProject.filter.sorts)

  const handleChangeSort = async (value: string) => {
    setSort(value)
    let newFilter = {}
    if (value !== sort) {
      newFilter = { ...listProject.filter, sorts: value }
      await dispatch(updateFilter(newFilter))
    }
  }

  return (
    <Space wrap>
      <div>
        <span>Sort by: </span>
        <Select
          defaultValue='createdAt'
          style={{ width: 120 }}
          onChange={handleChangeSort}
          value={sort}
          options={[
            { value: '-createdAt', label: 'Latest' },
            { value: 'createdAt', label: 'Oldest' }
          ]}
        />
      </div>
    </Space>
  )
}

export default SortProject
