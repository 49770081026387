import type { ThemeConfig } from 'antd'

const color = {
  primary: '#5379FF',

  border: '#dde2e8',

  main: '#111827',
  secondary: '#4B5563',
  tertiary: '#6B7280',
  quaternary: '#9CA3AF',

  error: '#FF4238',
  success: '#15C15D',

  light1: '#F4F4F4'
}

export const themeConfig: ThemeConfig = {
  token: {
    fontFamily: 'Inter, sans-serif',

    colorPrimary: color.primary,

    fontSize: 15,

    colorTextBase: color.main,
    colorTextPlaceholder: color.quaternary,

    colorBorder: color.border
  },

  components: {
    Input: {
      controlOutlineWidth: 0,
      colorBorder: color.border,
      colorBgContainer: '#ffffff',
      colorTextDisabled: color.tertiary,
      colorBgContainerDisabled: '#dde2e8'
    },

    Button: {
      controlOutlineWidth: 0,
      colorBgBase: '#f4f4f4'
    }
  }
}
