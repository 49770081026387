import '@/styles/index.scss'

import type { FC } from 'react'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Error404 from '@/layout/Error404'
import Layout from '@/layout/Layout'
import LoginPage from '@/modules/auth/LoginPage'

import HomePage from './modules/home/HomePage'
import { ProfileRoutes } from './modules/profile/ProfileRoutes'
import { ProjectsRoutes } from './modules/projects/ProjectsRoutes'
import { UsersRoutes } from './modules/users/UsersRoutes'

const App: FC = () => {
  const routesConfig = [...ProfileRoutes, ...UsersRoutes, ...ProjectsRoutes]

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderRoutes = (routes: any[]) =>
    routes.map((route) => (
      <Route key={route.path} path={route.path} element={route.element}>
        {route.children && renderRoutes(route.children)}
      </Route>
    ))

  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path='login' element={<LoginPage />} />

        {renderRoutes(routesConfig)}

        <Route path='*' element={<Error404 />} />
      </Route>
    </Routes>
  )
}

export default App
